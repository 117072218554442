import React from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
function Project() {
  return (
    <div>
      <main className='main-content'>
        <Header />
        <p>Project page</p>
        <Footer />
      </main>
    </div>
  );
}
export default Project;